<template>
  <v-app>
    <toolbar />
    <v-fade-transition>
      <router-view />
    </v-fade-transition>
  </v-app>
</template>

<script>
import toolbar from "./components/Toolbar.vue";

export default {
  name: "app",
  components: {
    toolbar,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss"></style>
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: '/en' },
  { path: "/index.html", redirect: '/' },

  {
    path: "/en",
    name: "en",
    component: () =>
      import(/* webpackChunkName: "english" */ "../views/Index.vue")
  },

  {
    path: "/es",
    name: "es",
    component: () =>
      import("../views/Index.vue")
  },
  {
    path: "/en-ca",
    name: "ca",
    component: () =>
      import("../views/Index.vue")
  },
  {
    path: "/fr-ca",
    name: "fr-ca",
    component: () =>
      import("../views/Index.vue")
  },
  {
    path: "/uk",
    name: "uk",
    component: () =>
      import("../views/Index.vue")
  },
  {
    path: "/fr",
    name: "fr",
    component: () =>
      import("../views/Index.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;

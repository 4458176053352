<template>
  <div id="toolbar">
    <v-toolbar
      class="px-xs-12 px-sm-3 px-md-6 px-lg-12"
      color="primary"
      flat
      dark
      height="50"
    >
      <v-img
        :src="require('@/assets/logos/logo-tbb-black-small.png')"
        aspect-ratio="1"
        :max-width="resizeLogo"
        background
        light
        flat
        contain
      ></v-img>

      <div class="flex-grow-1"></div>
      <div>
        <!-- Menu  -->
        <div>
          <!-- Menu  -->
          <v-menu v-model="menu" offset-y offset-x open-delay="100">
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col v-bind="attrs" v-on="on">
                  <v-icon class="black--text"> mdi-earth </v-icon>
                  <span
                    class="
                      font-weight-medium
                      text-caption text-md-body-2
                      mx-2
                      black--text
                    "
                    >{{ translate.title }}</span
                  >
                  <v-icon size="medium" class="black--text">
                    mdi-chevron-down
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <v-card max-width="300" style="border: none !important">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-body-2 text-sm-body-1">{{
                      translate.menu.lang.title
                    }}</v-list-item-title>
                    <v-divider class="my-3"></v-divider>
                    <v-list-item-content>
                      <v-row>
                        <v-col
                          cols="12"
                          v-for="(link, i) in translate.menu.lang.na.links"
                          :key="link[i]"
                        >
                          <!-- <v-img
                          :lazy-src="
                            './images/icons/flag-' + link.icon + '.png'
                          "
                          :src="'./images/icons/flag-' + link.icon + '.png'"
                          max-height="25"
                          position="left"
                          class="pl-9"
                          eager
                          contain
                        >
                          <router-link
                            :to="{
                              name: link.slug,
                            }"
                            class="text-body-2 text-sm-body-1"
                            >{{ link.title }}</router-link
                          >
                        </v-img> -->
                          <router-link
                            :to="{
                              name: link.slug,
                            }"
                            class="text-body-2 text-sm-body-1"
                            >{{ link.title }}</router-link
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" text @click="menu = false"> Exit </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      right
      width="300px"
      app
      floating
      disable-resize-watcher
      color="tertiary"
      mobile-breakpoint="1800"
    >
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <!-- Language Buttons -->

          <v-list-item v-show="language !== 'en-us'" to="/" :ripple="false">
            <v-list-item-content class="py-3 py-md-6">
              <h3 class="text-h6 white--text">English</h3>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { toolbar } from "@/data/toolbar.js";

export default {
  name: "toolbar",
  components: {},
  props: {
    menu: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    trackCustomLinks() {
      this.$gtag.event("view_subscription", {
        event_category: "engagement",
        event_label: this.product,
        value: this.product,
      });
    },
  },
  computed: {
    language() {
      return this.$route.params.lang;
    },
    resizeLogo() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 196;
        default:
          return 196;
      }
    },
    translate() {
      switch (this.$route.name) {
        case "es":
          return toolbar.es;
        case "fr":
          return toolbar.fr;
        default:
          return toolbar.en;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.resize-tbb-logo {
  max-width: 188px !important;
  max-height: 15px !important;
}

.toolbar {
  border-bottom: solid 1px #fff;
}
</style>

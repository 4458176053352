const slug = {
  lang: {
    en: "en",
    es: "es",
    fr: "fr"
  }
};

// Toolbar
export const toolbar = {
  // English
  en: {
    title: "English",
    menu: {
      lang: {
        title: "Select Your Preference",
        na: {
          title: "North America",
          links: [
            {
              title: "English",
              slug: slug.lang.en,
              icon: "us"
            },
            {
              title: "Español",
              slug: slug.lang.es,
              icon: "us"
            },
            {
              title: "Français",
              slug: slug.lang.fr,
              icon: "us"
            }
          ]
        }
      },
    }
  },
  // Espanol
  es: {
    title: "Español",
    menu: {
      lang: {
        title: "Selecciona tu preferencia",
        na: {
          title: "North America",
          links: [
            {
              title: "English",
              slug: slug.lang.en,
              icon: "us"
            },
            {
              title: "Español",
              slug: slug.lang.es,
              icon: "us"
            },
            {
              title: "Français",
              slug: slug.lang.fr,
              icon: "us"
            }
          ]
        },
      },
    }
  },

  // French
  fr: {
    title: "Francais",
    menu: {
      lang: {
        title: "Sélectionnez votre préférence",
        na: {
          title: "North America",
          links: [
            {
              title: "Anglais",
              slug: slug.lang.en,
              icon: "us"
            },
            {
              title: "Español",
              slug: slug.lang.es,
              icon: "us"
            },
            {
              title: "Français",
              slug: slug.lang.fr,
              icon: "us"
            }
          ]
        },
      },
    }
  },
};

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    light: true,
    themes: {
      light: {
        primary: '#9BAE89',
        secondary: '#CDD6C4',
        tertiary: '#F3F3F3',
        accent: '#1A99D3',
        error: '#2e2e2e',
        info: '#199DFC',
        success: '#E6E6E6',
        warning: '#FFC107',
        anchor: '#F3B15B'
      }
    },
  },
  icons: {
    iconfont: 'mdiSvg'
  },
});
